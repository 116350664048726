import { render, staticRenderFns } from "./PurchasesScreen.vue?vue&type=template&id=b5d21182&scoped=true&"
import script from "./PurchasesScreen.vue?vue&type=script&lang=js&"
export * from "./PurchasesScreen.vue?vue&type=script&lang=js&"
import style0 from "./PurchasesScreen.vue?vue&type=style&index=0&id=b5d21182&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5d21182",
  null
  
)

export default component.exports