<template>
  <div id="payback-verification-popup">
    <div class="wrapper" v-on-clickaway="handleClickAway">
      <error-toast
        class="error-toast"
        :isVisible="errorToast.isVisible"
        :text="errorToast.text"
      />
      <div class="title">
        {{ $t('general.payBackVerificationPopup.title') }}
      </div>
      <div class="paragraph">
        <span>
          {{ $t('general.payBackVerificationPopup.paragraph') }} {{ price | toCurrency }}
        </span>
      </div>
      <div class="button-row">
        <base-button
          :isLoading="isLoading"
          :isSecondary="true" :text="$t('general.payBackVerificationPopup.button.submit')"
          @clicked="refund"
        />
        <base-button
          :isSecondary="true" :text="$t('general.payBackVerificationPopup.button.cancel')"
          @clicked="cancelAction"
        />
      </div>
    </div>
    <div class="payback-popup-background"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import ErrorToast from '@/components/shared/elements/toasts/ErrorToast';

export default {
  name: 'PayBackVerificationPopup',
  mixins: [clickaway],
  components: {
    BaseButton,
    ErrorToast,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    errorToast: {
      isVisible: false,
      text: undefined,
    },
    isLoading: false,
  }),
  methods: {
    ...mapActions({
      refundOrder: 'orderActions/refundOrder',
    }),
    cancelAction() {
      this.$emit('cancel');
    },
    refund() {
      const data = {
        id: this.orderId,
      };
      this.isLoading = true;
      this.refundOrder(data).then(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        this.isLoading = false;
      }).catch((error) => {
        this.errorToast.isVisible = true;
        this.errorToast.text = error.data.message;
        setTimeout(() => {
          this.errorToast.isVisible = false;
          this.errorToast.text = undefined;
          this.isLoading = false;
        }, 3000);
      });
    },
    handleClickAway() {
      this.$emit('clickedAway');
    },
  },
};
</script>

<style lang="scss" scoped>
#payback-verification-popup {
  z-index: 5;
  .wrapper {
    position: relative;
    width: 320px;
    height: 266px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(112, 112, 112, 0.35);
    padding: 22px 8px;
    text-align: center;
    z-index: 10;
    @media screen and (max-width: $breakpointDownSm) {
      height: 238px;
    }

    .error-toast {
      background-color: white;
      border-radius: 10px;
      width: 320px;
      height: max-content;
      position: absolute;
      top: calc(50% - 200px);
      left: calc(50% - 160px);
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      color: $primaryBlue;
      text-transform: uppercase;
      line-height: 1;
      padding-bottom: 42px;

      @media screen and (max-width: $breakpointDownSm) {
        font-size: 16px;
      }
    }

    .button-row {
      padding-top: 42px;
      display: flex;
      justify-content: space-between;
    }
  }

  .payback-popup-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: rgba(112, 112, 112, 0.25);
  }
}
</style>
