<template>
  <div class='purchase-row'>
    <div class="date-col">{{ date }}</div>
    <div class="orderref-col">{{ orderRef }}</div>

    <div class="">
      <div class="row">
        <a :href="invoiceLink" target="_blank" class="invoice-number">{{ invoiceNumber }}</a>
      </div>
      <div class="row" v-for="(refund, i) in refundTransaction" :key="i">
        <a :href="refund.invoice.invoiceLink" target="_blank" class="invoice-number">{{ getInvoiceNumber(refund.invoice) }}</a>
      </div>
    </div>

    <div class="price-col">{{ price | toCurrency }}</div>
    <div class="validity-col">
      <div class="row" v-for="(date, i) in validityDates" :key="i">{{ date }}</div>
    </div>
    <div class="button-row" :class="needDateButton ? '' : 'just-two'">
      <div class="button-col">
        <base-button
          :text="$t('pages.purchases.buttons.ticketsAndInvoices')"
          :link="ticketLink"
        />
      </div>
      <div class="button-col" v-if="needDateButton">
        <base-button
          :text="$t('pages.purchases.buttons.dateChange')"
          @clicked="handleDateButtonClick"
        />
      </div>
      <div class="button-col" v-if="needRefoundButton">
        <base-button
          :text="$t('pages.purchases.buttons.payBack')"
          @clicked="refoundClicked"
        />
      </div>
    </div>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <date-picker-popup class="date-picker-popup"
        v-if="datePickerVisible"
        :orderId="orderId"
        @clickedAway="handleClickedAway"
        @dateUpdated="updateDate"
        @cancel="handleClickedAway"/>
    </transition>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <pay-back-verification-popup class="pay-back-verification-popup"
        v-if="payBackVerificationVisible"
        :price="price"
        :orderId="orderId"
        @clickedAway="handleClickedAway"
        @cancel="handleClickedAway"/>
    </transition>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import DatePickerPopup from '@/components/shared/elements/modals/DatePickerPopup';
import PayBackVerificationPopup from '@/components/shared/elements/modals/PayBackVerificationPopup';
import { mapActions } from 'vuex';

export default {
  name: 'PurchaseRow',
  props: {
    date: {
      type: String,
      required: true,
    },
    orderRef: {
      type: String,
      required: true,
    },
    invoiceNumber: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    ticketLink: {
      type: String,
      required: true,
    },
    invoiceLink: {
      type: String,
      required: true,
    },
    validityDates: {
      type: Array,
      required: true,
    },
    needDateButton: {
      type: Boolean,
      default: false,
    },
    needRefoundButton: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      required: true,
    },
    refundTransaction: {
      type: Array,
      required: true,
    },
  },
  components: {
    BaseButton,
    DatePickerPopup,
    PayBackVerificationPopup,
  },
  data: () => ({
    datePickerVisible: false,
    payBackVerificationVisible: false,
  }),
  created() {},
  computed: {},
  methods: {
    getInvoiceNumber(invoice) {
      console.log(invoice);
      let localIdentifier;

      if (invoice.responseData) {
        // eslint-disable-next-line prefer-destructuring
        localIdentifier = invoice.responseData.split('|')[2];
      }
      return localIdentifier;
    },
    handleBtnClick() {
      window.open(this.ticketLink, '_blank');
    },
    handleInvoiceDownloadClick() {
      window.open(this.invoiceLink, '_blank');
    },
    handleDateButtonClick() {
      this.datePickerVisible = true;
      document.body.style.overflow = 'hidden';
    },
    handleClickedAway() {
      document.body.style.overflow = null;
      this.datePickerVisible = false;
      this.payBackVerificationVisible = false;
    },
    updateDate(date) {
      this.validityDates[0] = date;
      this.handleClickedAway();
    },
    refoundClicked() {
      this.payBackVerificationVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.purchase-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  font-weight: 500;

  @media screen and (max-width: $breakpointDownMd) {
    grid-template-columns: repeat(4, 1fr) auto;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: 1fr 1fr 0.8fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 2px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
}

.date-picker-popup {
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - 160px);
}

.pay-back-verification-popup {
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 160px);
}

.invoice-number {
  color: $primaryBlue;
  text-decoration: underline;
  font-weight: 700;
  padding-left: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: $breakpointDownMd) {
    padding-right: 45px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 3 / 3 / 2;
    padding: 0;
    padding-right: 0;
    text-align: left;
    padding-left: 10px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding: 0 8px;
    grid-area: 1 / 2 / 3 / 2;
  }
}

.date-col {
  @media screen and (max-width: $breakpointDownSm) {
    white-space: nowrap;
    grid-area: 1;
  }

  @media screen and (max-width: $breakpointDownXs) {
    line-height: 1.2;
  }
}

.orderref-col {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 2 / 1 / 3 / 2;
  }

  @media screen and (max-width: $breakpointDownXs) {
    line-height: 1.2;
  }
}

.price-col {
  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 10px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    text-align: center;
    grid-area: 1 / 3 / 3 / 4;
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding-left: 0;
    text-align: center;
  }
}

.validity-col {
  .row {
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 12px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    white-space: nowrap;
    grid-area: 1 / 4 / 3 / 4;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-area: 1 / 4 / 3 / 5;
  }
}

.order-number {
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.button-row {
  @media screen and (max-width: $breakpointDownMd) {
    padding: 22px 0 10px 0;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    grid-area: 3 / 1 / 4 / 6;
    border-bottom: 1px solid rgba(94, 94, 94, 0.2);
  }

  @media screen and (max-width: $breakpointDownSm) {
    display: block;
    text-align: center;
  }
}

.button-col {
  white-space: nowrap;
  padding-bottom: 6px;
  display: flex;
  @media screen and (max-width: $breakpointDownSm) {
    grid-area: 3 / 1 / 4 / 6;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-area: 3 / 1 / 4 / 4;
  }

  .button-base {
    padding: 0 20px;
    line-height: 26px;

    @media screen and (max-width: $breakpointDownSm) {
      margin-top: 10px;
      width: 100%;
      font-size: 12px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      margin-top: 10px;
    }

    /deep/ {
      span {
        @media screen and (max-width: $breakpointDownSm) {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100px;
        }

        @media screen and (max-width: $breakpointDownMd) {
          margin: auto;
          max-width: min-content;
        }

        @media screen and (max-width: $breakpointDownXs) {
          max-width: none;
        }
      }
    }
  }
}
</style>
