<template>
  <div id='date-picker-popup'>
    <div class="wrapper" v-on-clickaway="handleClickAway">
      <error-toast
        class="error-toast"
        :isVisible="errorToast.isVisible"
        :text="errorToast.text"
      />
      <div class="header">
        <span class="title">{{ $t('general.datePickerPopup.title') }}</span>
      </div>

      <base-date-picker
        v-if="dateRange"
        :defaultValue="new Date(selectedDate)"
        :dateRange="dateRange"
        @picked="handleDateSelection"
      />

      <div class="selected-day">
        <span class="label">{{ $t('general.datePickerPopup.selectedDay') }}</span>
        <span class="date">{{ selectedDate | moment('YYYY.MM.DD.') }}</span>
        <span class="day-name">{{ selectedDate | moment('dddd') }}</span>
      </div>
      <div class="button-row">
        <base-button
          :isSecondary="true" :text="$t('general.datePickerPopup.button.submit')"
          @clicked="handleButtonClick"
        />
        <base-button
          :isSecondary="true" :text="$t('general.datePickerPopup.button.cancel')"
          @clicked="cancelAction"
        />
      </div>
    </div>
    <div class="date-picker-background"/>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import BaseDatePicker from '@/components/shared/elements/inputs/BaseDatePicker';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import ErrorToast from '@/components/shared/elements/toasts/ErrorToast';

export default {
  name: 'DatePickerPopup',
  mixins: [clickaway],
  components: {
    BaseDatePicker,
    BaseButton,
    ErrorToast,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selectedDate: undefined,
    dateRange: undefined,
    errorToast: {
      isVisible: false,
      text: undefined,
    },
  }),
  created() {
    this.selectedDate = this.formatDate(new Date(Date.now()));
    this.getPurchaseDateRange().then((resp) => {
      this.dateRange = resp.data;
    });
  },
  methods: {
    ...mapActions({
      getPurchaseDateRange: 'settings/getPurchaseDateRange',
      changeValidityDate: 'orderActions/changeValidityDate',
    }),
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    handleButtonClick() {
      if (this.selectedDate !== undefined) {
        const data = {};
        data.body = {
          date: this.selectedDate,
        };
        data.id = this.orderId;
        this.changeValidityDate(data).then(() => {
          this.$emit('dateUpdated', data.body.date);
        }).catch((error) => {
          this.errorToast.isVisible = true;
          this.errorToast.text = error.data.message;
          setTimeout(() => {
            this.errorToast.isVisible = false;
            this.errorToast.text = undefined;
          }, 3000);
        });
      }
    },
    handleDateSelection(val) {
      this.selectedDate = val;
    },
    handleClickAway() {
      this.$emit('clickedAway');
    },
    cancelAction() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
#date-picker-popup {
  z-index: 5;
  .wrapper {
    position: relative;
    width: 320px;
    height: 500px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(112, 112, 112, 0.35);
    padding: 22px 8px;
    text-align: center;
    z-index: 10;
    @media screen and (max-width: $breakpointDownSm) {
      height: 490px;
    }

    .error-toast {
      background-color: white;
      border-radius: 10px;
      width: 320px;
      height: max-content;
      position: absolute;
      top: calc(50% - 320px);
      left: calc(50% - 160px);
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      color: $primaryBlue;
      text-transform: uppercase;
      line-height: 1;

      @media screen and (max-width: $breakpointDownSm) {
        font-size: 16px;
      }
    }
    .selected-day {
      padding-bottom: 12px;
      .label {
        font-size: 10px;
        font-weight: 500;
        display: block;
        margin: 0 0 5px;
      }

      .date,
      .day-name {
        display: block;
        color: $primaryBlue;
        font-weight: 700;
        line-height: 1.2;
      }

      .date {
        font-size: 20px;

        @media screen and (max-width: $breakpointDownSm) {
          font-size: 16px;
        }
      }

      .day-name {
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    .button-row {
      /* padding-top: 42px; */
      display: flex;
      justify-content: space-between;
    }
  }

  .date-picker-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: rgba(112, 112, 112, 0.25);
  }
}
</style>
